import posthog from "posthog-js";

export const getCrispUrlForPosthogUser = (): string => {
    const CRISP_URL = "https://go.crisp.chat/chat/embed/?website_id=e194e478-f537-4e1d-b358-6daa5a271c58";
    const distinctId = posthog.get_distinct_id();
    return `${CRISP_URL}&user_nickname=${encodeURIComponent(distinctId)}`;
}

export const SUCCESS_COLOR = "#f68d8bff";
// TODO: update google ads when changing.
export const PRICE_STRING = "$14.99";

export enum Site {
    EIGHT_K = "EIGHT_K",
    REALVIEW = "REALVIEW",
    PHOTOREALISTIC = "PHOTOREALISTIC",
    THREE_D_ULTRASOUND_STUDIO = "THREE_D_ULTRASOUND_STUDIO",
}

export const getSite = () => {
    var r = Site[process.env.REACT_APP_SITE as keyof typeof Site]
    if (!r) {
        console.log("Falling back to PHOTOREALISTIC site.")
        r = Site.PHOTOREALISTIC;
    }
    return r;
};

export const isSite = (site: Site) => {
    return getSite() === site;
}

export enum Environment {
    PROD = "PROD",
    ALPHA = "ALPHA",
    MANUAL = "MANUAL",
}

export const getEnviroment = () => {
    var r = Environment[process.env.REACT_APP_ENVIRONMENT as keyof typeof Environment]
    if (!r) {
        console.log("Falling back to MANUAL environment.")
        r = Environment.MANUAL;
    }
    return r;
};

export const isEnvironment = (environment: Environment) => {
    return getEnviroment() === environment;
}

export const HCAPTCHA_TEST_SITE_KEY = "10000000-ffff-ffff-ffff-000000000001";
export const HCAPTCHA_PROD_SITE_KEY = "bac6dc54-f1ce-4cfe-81bd-11defda3cd69";
export const HCAPTCHA_SITE_KEY = isEnvironment(Environment.MANUAL) ? HCAPTCHA_TEST_SITE_KEY : HCAPTCHA_PROD_SITE_KEY;


export const GOOGLE_ANALYTICS_MEASUREMENT_ID = {
    [Site.PHOTOREALISTIC]: "G-XVKHE1CPBB",
    [Site.EIGHT_K]: "G-PGTJNGTZ3K",
    [Site.REALVIEW]: "G-VRN1CGKF2H",
    [Site.THREE_D_ULTRASOUND_STUDIO]: "G-CH20BWQNHG",
};

export const tiktokUrl = "https://www.tiktok.com/@photorealisticultrasound";
export const instagramUrl = "https://www.instagram.com/photorealisticultrasound";
export const twitterUrl = "https://twitter.com/PhotorealUsound"

// The rest in dropped.
export const MAX_NUMBER_OF_IMAGES_TO_UPLOAD_AT_ONCE = 100;


export const API_URL = process.env.REACT_APP_API_URL || "missing";

export const DISCLAIMER = "The provided service does not claim to predict or guarantee the appearance or gender of a baby. Any information or results obtained through the use of this service should not be considered as a definitive determination and are solely for entertainment purposes."
