import { ReactNode } from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { Link } from "react-router-dom";

interface Provider {
    url: string;
    name: string;
    price_usd: number;
    price_with_currency: string;
    label: string;
    exampleCount: number;
    exampleFormat: string;
    minBusinessDaysToGet: number;
    maxBusinessDaysToGet: number;
    domainRegisteredOn: Date;
}


const providers: Provider[] = [
    {
        name: "Babytures",
        url: "https://babytures.com/products/8k-ultrasound",
        price_usd: 19.99,
        price_with_currency: "$19.99",
        label: "babytures_8k",
        exampleCount: 2,
        exampleFormat: "webp",
        minBusinessDaysToGet: 2,
        maxBusinessDaysToGet: 2,
        domainRegisteredOn: new Date('2022-08-17'),

    },
    {
        // Registered On:2022-05-09
        name: "8K Ultrasound Images",
        url: "https://8kultrasoundimages.com/",
        price_usd: 17.95,
        price_with_currency: "$17.95",
        label: "8kultrasoundimages",
        exampleCount: 2,
        exampleFormat: "png",
        minBusinessDaysToGet: 3,
        maxBusinessDaysToGet: 4,
        domainRegisteredOn: new Date('2022-05-09'),
    },
    {
        name: "BabyReal8K",
        url: "https://babyreal8k.com/en/",
        price_usd: 28.05,
        price_with_currency: "24.95€",
        label: "babyreal8k",
        exampleCount: 2,
        exampleFormat: "webp",
        minBusinessDaysToGet: 1,
        maxBusinessDaysToGet: 2,
        domainRegisteredOn: new Date('2021-11-26'),
    },
]
// https://eco8k.com/ no b2c
// domainRegisteredOn: new Date('2021-11-26'),
// 2021-11-23

const getExampleImageUrls: (provider: Provider) => string[] = (provider: Provider) => {
    const urls: string[] = [];

    for (let i = 1; i <= provider.exampleCount; i++) {
        const imageUrl = require(`../img/8k/${provider.label}_${i}.${provider.exampleFormat}`);
        urls.push(imageUrl);
    }

    return urls;
};


const buildProviderUrlElement: (provider: Provider) => ReactNode = (provider: Provider) =>
    <a href={provider.url} target="_blank" rel="noopener noreferrer">
        {provider.name}
    </a>;

const getBusinessDayRange: (provider: Provider) => string = (provider: Provider) =>
    provider.minBusinessDaysToGet === provider.maxBusinessDaysToGet ? `${provider.minBusinessDaysToGet}` : `${provider.minBusinessDaysToGet} - ${provider.maxBusinessDaysToGet}`





export interface Post {
    url: string;
    title: string;
    description: string;
    header: string;
    tableOfContentItem: string;
    content: ReactNode;
}

const requirementsUrl = '/requirements-for-best-quality';
const examplesUrl = '/example-images-by-provider';

const costUrl = '/cost';
export const posts: Post[] = [
    {
        url: '/what-is-8k-explained',
        header: 'What is 8K Fetal Ultrasound?',
        tableOfContentItem: "What is 8K Fetal Ultrasound?",
        title: 'What is 8K Ultrasound Exactly? | A Clear & Comprehensive Guide',
        description: 'Ever wondered what an 8K Ultrasound is? Dive into our simple, jargon-free guide that takes you through the 8K ultrasound process from A to Z.',
        content: <>
            <p>Getting your first glimpse of your unborn baby is an emotional roller coaster, filled with excitement, anticipation, and pure love. Traditional ultrasounds can provide this look, but their details are often quite basic. Would you like to take it a step further? That's where 8K ultrasound comes in!</p>

            <h2>Exploring the 8K Ultrasound</h2>

            <p> 8K Ultrasound Image Enhancement is a technique used to edit 3D ultrasound images of your baby to make them appear more lifelike. This process involves adding skin tone and details like eyelashes and eyebrows. This makes it easier for parents to feel a connection with their unborn child. It is essential to clarify that this enhancement is applied only to the 3D ultrasound; 8K is not an ultrasound technology and does not influence how your 3D ultrasounds are produced.</p>

            <p>The name '8K' does not refer to the image resolution quality, which is 7680x4320 pixels. In fact, the resulting images never reach this resolution.</p>

            <p>So, there you have it! 8K Ultrasound, a technology that enables you to see your unborn baby in greater detail and vibrant colors. It's like getting an advanced sneak peek into their tiny world!</p>
        </>
    },
    {
        url: examplesUrl,
        header: 'Example 8K Sonogram images by provider',
        tableOfContentItem: "Example images by provider",
        title: 'Top Quality 8K Ultrasound Image Samples By Leading Providers',
        description: 'Discover a comprehensive collection of top-quality 8K ultrasound samples by leading providers. Choose wisely to get the best imaging results.',
        content: <>
            Much like every artist possesses their unique approaches and styles, providers of 8K Ultrasound also produce varying results. Here are some examples, arranged in alphabetical order:

            {providers.sort((a, b) => a.name.localeCompare(b.name)).map((provider) => <>
                <Row className="py-3">
                    <h2>{buildProviderUrlElement(provider)}</h2>
                    {getExampleImageUrls(provider).map(
                        (url: string, i: number) => <Col xs={6}><img src={url} style={{ maxWidth: "100%" }} className='rounded' alt={`Example 8K Ultrasound by ${provider.name}`} /> </Col>
                    )}
                </Row>
            </>)}
        </>
    },
    {
        url: costUrl,
        title: 'Unveiling the Real Price of 8K Baby Ultrasound | Save Money Now',
        header: 'Price of 8K Ultrasound?',
        tableOfContentItem: "How much does it cost?",
        description: 'Want to get 8K ultrasound but confused about how much it costs and how to save on it? Get the comprehensive cost breakdown here...',
        content: <>
            <p>There are two essential ways for you to get 8K baby ultrasound enhancement:</p>
            <ol>
                <li>Through a studio, where you can add the 8K utility as an add-on to your existing 3D/4D/HD ultrasound package   </li>
                <li> or order straight from the 8K supplier.</li>
            </ol>

            <p>While ordering from a studio, the cost typically hovers around $25-$40 per enhanced image.
                The good news? You can save some cash by ordering directly from an 8K service provider:</p>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Price</th>
                        <th>Name</th>
                    </tr>
                </thead>
                <tbody>
                    {providers.sort((a, b) => a.price_usd - b.price_usd).map((provider) => (
                        <tr key={provider.name}>
                            <td>{provider.price_with_currency}</td>
                            <td>
                                {buildProviderUrlElement(provider)}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <p>Pro tip! Before ordering directly from a service provider, ensure the studio exports the ultrasound images from the machine. See <Link to={requirementsUrl}>more ways to improve quality of the result</Link>.</p>

            <p>Note, the base cost of your ultrasound at the studio isn’t included in these prices.</p>
        </>
    },
    {
        url: '/delivery-time-by-provider',
        title: 'How long does 8K Ultrasound take to produce?',
        header: 'How long does 8K Ultrasound take to produce?',
        tableOfContentItem: "Delivery times",
        description: 'Discover the duration it takes for different providers to produce a 8K ultrasound. Make an informed decision before placing your order.',
        content: <>
            <p> If you choose to order your 8K enhancement through a studio, you might want to ask the studio about their provider's typical delivery time. If you order directly from a provider instead, here's an overview of their delivery estimates, listed by their maximum timeframes: </p>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Business days</th>
                        <th>Name</th>
                    </tr>
                </thead>
                <tbody>
                    {providers.sort((a, b) => a.maxBusinessDaysToGet - b.maxBusinessDaysToGet).map((provider) => (
                        <tr key={provider.name}>
                            <td>{getBusinessDayRange(provider)}</td>
                            <td>
                                {buildProviderUrlElement(provider)}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    },
    {
        url: '/how-to-get',
        title: 'How to Get 8K Ultrasound Enhancement',
        header: 'How to Get 8K Ultrasound Enhancement',
        tableOfContentItem: "How to get",
        description: 'Learn about the step-by-step process to get your 8K Ultrasound Enhancement. Find out the best way.',
        content: <>
            <p> Because 8K Ultrasound Enhancement is an upgrade of a 3D ultrasound, you initially need to secure a 3D ultrasound of your child.This might be conducted as part of your regular ultrasound check-up or alternately you may locate a keepsake 3D ultrasound studio. </p>

            <p> It's important to note that a standard 2D ultrasound (typically in black and white) won't be sufficient for these advanced features. However, a 4D ultrasound will suffice, provided you capture the best moment in the video. </p>

            <h2>Options for Getting 8K Ultrasound</h2>

            <p> When obtaining 3D ultrasound, you have 2 options: </p>

            <ol>
                <li> If your 3D ultrasound studio also offers 8K Enhancement, you can simply opt for this as an addon service.  </li>
                <li> Alternatively, you can first obtain the 3D ultrasound from your studio of choice and then order the 8K Enhancement separately from a provider that specializes in this technology.</li>
            </ol>

            <p>Choosing the studio option typically means less work for you and a more cohesive experience, but this convenience usually comes at a higher price. Head over to our <Link to={costUrl}>cost overview</Link> for more information on pricing. If you decide to work directly with an 8K Enhancement provider, make sure you get the highest quality images from the ultrasound studio, as this is crucial for optimal results. Take a look at our detailed <Link to={requirementsUrl}>requirements</Link> for more insights.</p>
        </>
    },
    {
        url: requirementsUrl,
        header: 'Requirements for 8K Ultrasound Rendering?',
        tableOfContentItem: "Requirements / how to get best result.",
        title: 'Guide on Meeting the Requirements for 8K Ultrasound ',
        description: 'We delve into the key considerations and requirements for a successful 8K ultrasound. Learn when, how, and what you need for the optimal-quality image!',
        content: <>

            <p>
                Now, let's get straight to the point. How can you ensure you have a clear, high definition, 8K ultrasound image of your baby?
            </p>

            <p>
                To get the most comprehensive image of your baby, it's best to schedule the 3D Ultrasound between weeks 28 to 30. This window offers optimal conditions - the baby has adequate size, and there is an optimal amount of fluid surrounding them. Before 28 weeks the baby is still very small, so the ultrasound might be less detailed. Conversely, an ultrasound taken after 30 weeks may yield obstructed images due to the larger baby size and decreased fluid surrounding it.
            </p>

            <p>
                Most importantly, be sure to export the image directly from the ultrasound machine. While snapping a photo of the screen or a printed image can be tempting, it significantly reduces the quality of the 3D ultrasound image. This lowered quality may either result in a lower quality enhanced image or have the 8K provider refuse to process it.</p>

            <p> Moreover, ask to export all ultrasound images taken during the session. This way you can choose the best one for the 8K enhancement.</p>

            <p>
                Remember, better quality of 3D Ultrasound images leads to a more detailed and clear 8K image. So, follow these steps and get the best out of your 8K ultrasound experience!</p>


            <p> Another thing to bear in mind is that every artist has their own approach and style. Therefore, the final outcome can differ based on the provider. You can view <Link to={examplesUrl}>example results</Link> for each provider & pick the one you like the most. </p>
        </>
    },
    {
        url: '/is-it-safe',
        title: 'Safety of 8K Ultrasound Photos: Is It Completely Risk-Free?',
        header: 'Safety Aspects of 8K Ultrasound Photo Technology',
        tableOfContentItem: "Safety",
        description: 'Explore the safety of 8K Ultrasound Images in this comprehensive guide. Get all your concerns addressed.',
        content: <>

            <p>
                Your health and the health of your unborn child is of utmost importance. Facing new technological advancements often brings up a multitude of questions and concerns. One frequent question is about the safety of 8K ultrasound images.
            </p>

            <p>
                Here's the scoop: an 8K ultrasound image is a highly detailed modification of a conventional 3D ultrasound image, with no additional health risks. This cutting-edge innovation doesn't introduce any new scanning methods, thereby guaranteeing absolute safety. To put it simply, an 8K ultrasound is more like an artistically enhanced version of a 3D ultrasound image, rather than a new layer of ultrasound scanning by a professional. If you already own a 3D ultrasound image, there's no need to leave your home to get an 8K ultrasound image–you can simply place an order online by submitting your 3D ultrasound image.
            </p>

            <p> As such, the biggest risk you might encounter is internet scams or receiving poor quality 8K enhancements. To avoid any unpleasant surprises, refer to our list of <Link to={examplesUrl}>8K Providers with example images</Link>.</p>
        </>



    },
    {
        url: '/necessity',
        title: 'Is the 8K Fetal Ultrasound a Must-Have?',
        header: 'The Necessity of 8K Fetal Ultrasound',
        tableOfContentItem: "Necessity",
        description: 'Exploring the necessity and benefits of 8K fetal ultrasound.',
        content: <>

            <p>
                Despite popular belief, an 8K fetal ultrasound isn't a medical or diagnostic requirement. It's just an artistic upgrade to the conventional 3D ultrasound. The 8K ultrasound makes the 3D image more realistic by adding details like skin tone, texture, eyelids, and eyebrows, instead of the basic yellow, smooth, and blurry 3D ultrasound image.
            </p>

            <p> Just like 3D keepsake ultrasounds, 8K ultrasounds can help build a deeper connection with your unborn baby by satisfying your curiosity. Since 8K ultrasounds have higher image resolution than the standard 3D ultrasound, they might be a better choice for social media sharing.</p>

        </>



    },


]

